/**
 * Why is this a template?
 * In order to add pagination to the things page we need to use createPagination
 * during the onCreatePages Gatsby hook. Therefore, we need to create it dynamically
 * using a template instead of putting it into the regular Gatsby /pages folder
 */

import React, { Component } from 'react'
import styled from 'styled-components'
import throttle from 'lodash/throttle'

import Layout from '@components/Layout'
import { RichText } from '@components/Media'
import withDarkMode from '@components/DarkMode'
import Progress from '@components/Progress'
import ProgressMobile from '@components/Progress/Progress.Mobile'
import Section from '@components/Section'
import NavigationFooter from '@components/Navigation/Navigation.Footer'

import media from '@styles/media'
import { debounce } from '@utils'

import Aside from '../../sections/article/Article.Aside'
import ThingHero from '../../sections/thing/Thing.Hero'
import ArticleControls from '../../sections/article/Article.Controls'
import ThingPreview from '../../sections/thing/Thing.Preview'
import ThingMicrodata from '../../sections/thing/Thing.Microdata'
import ArticleShare from '../../sections/article/Article.Share'

import { IThingPage } from '@typings'

interface PostState {
  previousPath: string
}

interface ThingProps extends IThingPage {
  mode: string
  toggleMode: () => void
}

class Thing extends Component<ThingProps, PostState> {
  contentSectionRef: React.RefObject<HTMLElement> = React.createRef()
  hasCalculatedHeightBefore = false
  thing = this.props.pageContext.thing
  next = this.props.pageContext.next
  relateds = this.props.pageContext.relateds

  state = {
    showProgress: true,
    contentHeight: 0,
    contentOffset: 0,
    previousPath: null,
    trackingData: null,
  }

  componentDidMount() {
    this.calculateBodySize()
    window.addEventListener('resize', this.calculateBodySize)

    // Setting path of the Progress close buttoon
    if (typeof window !== 'undefined') {
      const previousPath =
        this.props.location.state === undefined ||
        this.props.location.state === null
          ? '/'
          : this.props.location.state.previousPath

      this.setState({
        previousPath: previousPath,
      })
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.calculateBodySize)
    }
  }

  /**
   * Calculate the size of our component. This is initially equal to
   * the component's height but then we want to recalculate everytime
   * an image in the content's body has loaded
   */
  calculateBodySize = throttle(() => {
    // Get the narrow content section
    const $contentSection = this.contentSectionRef.current!

    if (!$contentSection) {
      return
    }

    // If we haven't checked the content's height before,
    // we want to add listeners to the content area's
    // imagery to recheck when it's loaded
    if (!this.hasCalculatedHeightBefore) {
      const debouncedCalculation = debounce(this.calculateBodySize)
      const $imgs = $contentSection.querySelectorAll('img')
      $imgs.forEach(($img) => {
        // If the image hasn't finished loading then add a listener
        if (!$img.complete) $img.onload = debouncedCalculation
      })

      // // Handle embedded tweets
      // twttr.ready(twttr => {
      //   twttr.events.bind('rendered', this.calculateBodySize)
      // })

      // Prevent rerun of the listener attachment
      this.hasCalculatedHeightBefore = true
    }

    // Set the height and offset of the content area
    this.setState({
      contentHeight: $contentSection.getBoundingClientRect().height,
      contentOffset: $contentSection.offsetTop,
    })
  }, 20)

  render = () => {
    const { contentHeight, contentOffset, previousPath } = this.state
    const { location, mode, toggleMode } = this.props

    const scrollInfo = { height: contentHeight, offset: contentOffset }
    const thing = this.thing
    const author = this.thing.author
    const next = this.next
    const shortUrl = thing.shortUrl
      ? thing.shortUrl
      : `julien.projekt-43.org/${thing.path}`

    const navConfig = {
      theme: 'dark',
      offset: false,
      fixed: false,
      low: true,
    }

    return (
      <Layout nav={navConfig}>
        <ThingMicrodata thing={thing} location={location} />
        <ThingHero thing={thing} />
        <Aside {...scrollInfo}>
          <Progress {...scrollInfo} {...scrollInfo} />
        </Aside>
        <Aside right {...scrollInfo}>
          <ArticleControls
            toggleMode={toggleMode}
            mode={mode}
            shortUrl={shortUrl}
          />
        </Aside>
        <MobileControls>
          <ArticleControls
            toggleMode={toggleMode}
            mode={mode}
            shortUrl={shortUrl}
          />
        </MobileControls>
        <Content contentRef={this.contentSectionRef} content={thing.body}>
          <ArticleShare article={thing} mode={mode} />
        </Content>
        <Gradient>
          <Meta>
            <div>Posted on {thing.postDate}</div>
            <div>
              By {author.name}
              <span>{author.title && ` — ${author.title}`}</span>
            </div>
          </Meta>
          <NextArticle narrow>
            <FooterNext>Next thing by J.</FooterNext>
            <ThingPreview things={next} />
            <FooterSpacer />
          </NextArticle>
          <NavigationFooter mode={mode} to="/things" text="Back to Things" />
        </Gradient>
        <ProgressMobile
          mode={mode}
          title={thing.title}
          previous={previousPath}
          {...scrollInfo}
        />
      </Layout>
    )
  }
}

export default withDarkMode(Thing)

const MobileControls = styled.div`
  position: relative;
  padding-top: 65px;
  background: ${(p) => p.theme.mode.background};
  transition: background 0.2s linear;
  text-align: center;

  ${media.desktop_up`
    display: none;
  `}
`

const Content = styled(RichText).attrs<{ textHighlightColor: string }>({})`
  position: relative;
  padding: 160px 0 35px;
  background: ${(p) => p.theme.mode.background};
  transition: background 0.2s linear;

  ${media.tablet`
    padding: 60px 0 10px;
  `}
`

const Gradient = styled.div`
  position: relative;
  background: ${(p) => p.theme.mode.gradient};
  transition: background 0.4s ease-in-out;
`

const Meta = styled.div`
  width: 100%;
  max-width: 680px;
  margin: 0 auto;
  font-size: 1.2rem;
  color: ${(p) => p.theme.mode.text};
  margin-bottom: 80px;

  & span {
    opacity: 0.5;
  }

  ${media.tablet`
    padding: 0 20px;
    margin-bottom: 50px;
  `};
`

const NextArticle = styled(Section)`
  display: block;
`

const FooterNext = styled.h3`
  position: relative;
  opacity: 0.25;
  margin-bottom: 100px;
  font-weight: 400;
  color: ${(p) => p.theme.mode.text};

  ${media.tablet`
    margin-bottom: 50px;
  `}

  &::after {
    content: '';
    position: absolute;
    background: ${(p) => p.theme.mode.text};
    width: ${(910 / 1140) * 100}%;
    height: 1px;
    right: 0;
    top: 11px;

    ${media.tablet`
      width: ${(600 / 1140) * 100}%;
    `}

    ${media.phablet`
      width: ${(400 / 1140) * 100}%;
    `}

    ${media.phone`
      width: 90px
    `}
  }
`

const FooterSpacer = styled.div`
  margin-bottom: 65px;
`
