import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import Heading from '@components/Heading'
import Image from '@components/Image'

import media from '@styles/media'

import { IThingNode } from '@typings'

/**
 * Sits at the bottom of our Thing page. Shows the next 2 on desktop and the
 * next 1 on mobile!
 *
 * If you haven't noticed, this is basically a stripped down copy and paste
 * of Things.Grid.tsx. It works well for now but eventually we may want to
 * abstract the <Grid>...items</Grid> since there’s duplicate code.
 *
 *  [..............], [.........]
 *  [.....LONG.....], [..SHORT..]
 *  [..............], [.........]
 */
const ThingsPreview = ({ things }: { things: IThingNode[] }) => {
  if (!things) return null

  return (
    <>
      <Grid>
        <GridItem thing={things[0]} />
        <GridItem thing={things[1]} narrow />
      </Grid>
    </>
  )
}

export default ThingsPreview

const GridItem = ({
  thing,
  narrow,
}: {
  thing: IThingNode
  narrow?: boolean
}) => {
  if (!thing) return null

  const hasOverflow = narrow && thing.title.length > 35

  return (
    <ThingLink to={`/things/${thing.slug}`} data-a11y="false">
      <Item>
        <ImageContainer background={thing.backgroundColor}>
          <Image src={thing.backgroundImage.wide.fluid} />
        </ImageContainer>
        <Title dark hasOverflow={hasOverflow}>
          {thing.title}
        </Title>
        <Excerpt narrow={narrow} hasOverflow={hasOverflow}>
          {thing.excerpt}
        </Excerpt>
        <TimeToRead>{thing.readingTime.text}</TimeToRead>
      </Item>
    </ThingLink>
  )
}

const wide = '1fr'
const narrow = '457px'

const limitToTwoLines = css`
  text-overflow: ellipsis;
  overflow-wrap: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  white-space: normal;
  overflow: hidden;

  ${media.phablet`
    -webkit-line-clamp: 3;
  `}
`
const Grid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: ${(p) =>
    p.reverse ? `${narrow} ${wide}` : `${wide} ${narrow}`};
  grid-template-rows: 2;
  column-gap: 30px;

  ${media.desktop`
    grid-template-columns: 1fr 1fr;
  `}

  ${media.tablet`
    grid-template-columns: 1fr;
  `}
`

const ImageContainer = styled.div`
  position: relative;
  height: 280px;
  box-shadow: 0 30px 60px -10px rgba(0, 0, 0, ${(p) => (p.narrow ? 0.22 : 0.3)}),
    0 18px 36px -18px rgba(0, 0, 0, ${(p) => (p.narrow ? 0.25 : 0.33)});
  border-radius: 5px;
  margin-bottom: 30px;
  background-color: ${(p) => p.background};
  transition: transform 0.3s var(--ease-out-quad),
    box-shadow 0.3s var(--ease-out-quad);

  & > div {
    height: 100%;
    border-radius: 5px;
  }

  ${media.tablet`
    height: 240px;
    margin-bottom: 0;
    box-shadow: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  `}

  ${media.phablet`
    height: 200px;
  `}
`

const Item = styled.div`
  position: relative;

  ${media.tablet`
    margin-bottom: 40px;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    background: ${(p) => p.theme.mode.preview.bg};
  `}
`

const Title = styled(Heading.h3)`
  font-size: 22px;
  line-height: 1.2;
  margin-bottom: ${(p) => (p.hasOverflow ? '45px' : '10px')};
  color: ${(p) => p.theme.mode.text};
  transition: color 0.3s ease-in-out;
  ${limitToTwoLines};

  ${media.tablet`
    padding: 30px 20px 0;
    margin-bottom: 10px;
    -webkit-line-clamp: 3;
  `}
`

const Excerpt = styled.p`
  ${limitToTwoLines};
  font-size: 1.6rem;
  margin-bottom: 10px;
  color: ${(p) => p.theme.colors.grey};
  display: ${(p) => (p.hasOverflow ? 'none' : 'box')};
  max-width: ${(p) => (p.narrow ? '415px' : '515px')};

  ${media.desktop`
    display: -webkit-box;
  `}

  ${media.tablet`
    max-width: 100%;
    padding:  0 20px;
    margin-bottom: 20px;
    -webkit-line-clamp: 3;
  `}
`

const TimeToRead = styled.div`
  font-weight: 800;
  font-size: 1.6rem;
  color: ${(p) => p.theme.mode.text};
  opacity: 0.25;

  ${media.tablet`
    max-width: 100%;
    padding:  0 20px 30px;
  `}
`

const ThingLink = styled(Link)`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 5px;
  z-index: 1;
  transition: transform 0.33s var(--ease-out-quart);
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  &:hover ${ImageContainer} {
    transform: translateY(-1px);
    box-shadow: 0 50px 80px -20px rgba(0, 0, 0, 0.27),
      0 30px 50px -30px rgba(0, 0, 0, 0.3);
  }

  &:hover h2,
  &:focus h2 {
    color: ${(p) => p.theme.colors.blue};
  }

  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: -2%;
    top: -2%;
    width: 104%;
    height: 104%;
    border: 3px solid ${(p) => p.theme.colors.blue};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }

  ${media.tablet`
    &:hover ${ImageContainer} {
      box-shadow: none;
    }

    &:hover h2 {
      color: #000;
    }

    &:active {
      transform: scale(0.97) translateY(3px);
    }
  `}
`
